.button-core_button__XSJGj {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-core_button__XSJGj:disabled {
    background-color: var(--color-background-light);
    color: var(--color-text-light);
    cursor: not-allowed;
}

.button-core_large__2cDDV {
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
}

.button-core_small___Zz0m {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    height: 40px;
}

.button-core_primary__oSsut {
    background-color: var(--color-background-brand-green);
    color: var(--color-text-primary-inverse);
}

.button-core_primary__oSsut:hover {
    background-color: var(--color-background-brand-green-hovered);
}

.button-core_primary__oSsut:focus {
    background-color: var(--color-background-brand-green-hovered);
}

.button-core_primary__oSsut:active {
    background-color: var(--color-background-brand-green-hovered);
}

.button-core_primary__oSsut:disabled:hover {
    background-color: var(--color-background-light);
}

.button-core_accent__r0yil {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.button-core_accent__r0yil:hover {
    background-color: var(--color-background-brand-orange-hovered);
}

.button-core_accent__r0yil:focus {
    background-color: var(--color-background-brand-orange-hovered);
}

.button-core_accent__r0yil:active {
    background-color: var(--color-background-brand-orange-hovered);
}

.button-core_secondary__YXQjY {
    background-color: var(--color-background-secondary);
    color: var(--color-text-brand-green);
}

.button-core_secondary__YXQjY:hover {
    background-color: var(--color-background-secondary-hovered);
}

.button-core_secondary__YXQjY:focus {
    background-color: var(--color-background-secondary-hovered);
}

.button-core_secondary__YXQjY:active {
    background-color: var(--color-background-secondary-hovered);
}

.button-core_outline__9t0QT {
    background-color: var(--color-background-primary);
    border: 1px solid;
    border-color: var(--color-border-primary-inverse);
    color: var(--color-text-primary);
}

.button-core_outline__9t0QT:hover {
    background-color: var(--color-background-secondary);
    border: 1px solid var(--color-border-brand-hovered);
    color: var(--color-text-primary);
}

.button-core_outline__9t0QT:focus {
    border: 1px solid var(--color-border-brand-hovered);
    color: var(--color-text-brand-green);
}

.button-core_outline__9t0QT:active {
    background-color: var(--color-background-brand-heavy);
    border: 1px solid var(--color-border-primary-inverse);
    color: var(--color-text-primary);
}

.button-core_ghost__yuquA {
    background-color: var(--color-background-primary);
    border: none;
    color: var(--color-text-brand-green);
}

.button-core_ghost__yuquA:hover {
    background-color: var(--color-background-secondary);
    border: none;
    color: var(--color-text-brand-green);
}

.button-core_ghost__yuquA:focus {
    color: var(--color-text-brand-green);
}

.button-core_ghost__yuquA:active {
    background-color: var(--color-background-primary);
    border: none;
    color: var(--color-text-brand-green);
}

.button-core_grey__aQE9U {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-secondary);
}

.button-core_grey__aQE9U:hover {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-brand-green-hovered);
}

.button-core_grey__aQE9U:focus {
    color: var(--color-text-brand-green-hovered);
}

.button-core_grey__aQE9U:active {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-brand-green-hovered);
}

.button-core_link__C49TP {
    border: none;
    color: var(--color-text-brand-green);
    padding-left: 0;
}

.button-core_right-paddings__QLGvX {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
}

.button-core_left-paddings__remlJ {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
}

.input-core_input-container__XTU2P {
    position: relative;
}

.input-core_input-icon__8xH0U {
    position: absolute;
    pointer-events: none;
    right: 4%;
    top: 19%;
    color: rgba(149, 149, 149, 1);
    width: 23px;
    height: 23px;
    z-index: 10;
    background: rgba(251, 251, 251, 1);
    padding: 2px;
}

.input-core_input__db4mI::placeholder {
    font-size: 14px;
}

.input-core_input__db4mI:-webkit-autofill,
.input-core_input__db4mI:-webkit-autofill:hover,
.input-core_input__db4mI:-webkit-autofill:focus,
.input-core_input__db4mI:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(79, 79, 79, 1);
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.typography-core_h1__N_tBg {
    font-size: 60px;
    font-weight: 600;
    line-height: 64px;
    color: var(--color-text-primary);
}

.typography-core_h2__GCXkm {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: var(--color-text-primary);
}

.typography-core_h3__9AJ59 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: var(--color-text-primary);
    letter-spacing: -0.5px;
}

.typography-core_l__vx_LV {
    font-size: 20px;
    line-height: 24px;
}

.typography-core_m__sYYZb {
    font-size: 16px;
    line-height: 20px;
}

.typography-core_s__fE4dd {
    font-size: 14px;
    line-height: 20px;
}

.typography-core_xs__yH9c6 {
    font-size: 13px;
    line-height: 20px;
}

.typography-core_xxs__CKkP1 {
    font-size: 11px;
    line-height: 12px;
}

.typography-core_bold__yIV7c {
    font-weight: 600;
    letter-spacing: -0.5px;
}

.typography-core_medium__dgPCf {
    font-weight: 500;
    letter-spacing: -0.5px;
}

.typography-core_regular__d3l32 {
    font-weight: 400;
    letter-spacing: -0.5px;
}

.typography-core_primary__7zN6m {
    color: var(--color-text-primary);
}

.typography-core_secondary__hX8x7 {
    color: var(--color-text-secondary);
}

.typography-core_light__WLHHE {
    color: var(--color-text-light);
}

.typography-core_heavy__7whPm {
    color: var(--color-text-heavy);
}

.typography-core_inverse__0pEkL {
    color: var(--color-text-primary-inverse);
}

.typography-core_green-brand__cSBd0 {
    color: var(--color-text-brand-green);
}

.typography-core_orange-brand__qFvMp {
    color: var(--color-text-brand-orange);
}

@media (min-width: 850px) {
    .typography-core_h1__N_tBg {
        letter-spacing: 2%;
    }

    .typography-core_h2__GCXkm {
        letter-spacing: 2%;
    }

    .typography-core_h3__9AJ59 {
        letter-spacing: 0.02em;
    }

    .typography-core_l__vx_LV {
        letter-spacing: 0.02em;
    }

    .typography-core_m__sYYZb {
        letter-spacing: 0.02em;
    }

    .typography-core_s__fE4dd {
        letter-spacing: 0.02em;
    }

    .typography-core_xs__yH9c6 {
        letter-spacing: 0.02em;
    }

    .typography-core_bold__yIV7c {
        letter-spacing: 0.02em;
    }

    .typography-core_medium__dgPCf {
        letter-spacing: 0.02em;
    }

    .typography-core_regular__d3l32 {
        letter-spacing: 0.02em;
    }
}

@font-face {
font-family: '__sfProText_c94a2b';
src: url(/_next/static/media/93912fe29692ae09-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sfProText_c94a2b';
src: url(/_next/static/media/1019034e70331a3a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sfProText_c94a2b';
src: url(/_next/static/media/84be793b33f5517c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__sfProText_Fallback_c94a2b';src: local("Arial");ascent-override: 88.96%;descent-override: 22.54%;line-gap-override: 0.00%;size-adjust: 107.03%
}.__className_c94a2b {font-family: '__sfProText_c94a2b', '__sfProText_Fallback_c94a2b'
}

.cookie-accept_document-button__narS6 {
    display: inline !important;
}

.cookie-accept_container__w5JQ3 {
    display: none;
}

@media (min-width: 850px) {
    .cookie-accept_container__w5JQ3 {
        position: fixed;
        bottom: 2%;
        left: 50%;
        width: max-content;
        max-width: 100vw;
        transform: translateX(-50%);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 10px 8px;
        border-radius: 12px;
        border: none;
        background-color: var(--color-background-primary);
        justify-content: space-between;
        align-items: baseline;
    }
}

.bottom-navigation_bottomNav__xLumr {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    z-index: 1000;
}

.bottom-navigation_bottomNav__xLumr:hover > a {
    text-decoration: none;
}

.bottom-navigation_navItem__SZtjp {
    flex: 1 1;
    text-align: center;
    padding-top: 2px;
    text-decoration: none;
}

.bottom-navigation_badge__fIx5f {
    position: absolute;
    top: -7%;
    right: 10%;
    background-color: var(--color-background-brand-green);
    border-radius: 10px;
    padding: 2px 4px;
    border: 1px solid var(--color-background-primary);
}

.bottom-navigation_badge-text__2yF_u {
    max-height: 1rem;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.07px;
    color: var(--color-text-primary-inverse);
}

@media (min-width: 850px) {
    .bottom-navigation_bottomNav__xLumr {
        display: none;
    }
}

.custom-cluster-mobile_button-width__5wZqI {
    width: 100%;
}

.custom-cluster-mobile_partner-container__lE3aQ {
    margin-bottom: 20px;
}

.custom-marker-mobile_icon-marker__hbkgp {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 50px;
    color: linear-gradient(0deg, #fd6b00 0%, #ea6300 37.53%, #da5c00 67.22%, #c95500 100%);
    z-index: 0;
    position: relative;
    transform: translate(-50%, -80%);
}

.custom-marker-mobile_point-name__IlEGC {
    font-size: 10px;
    max-width: 15vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-marker-mobile_point-name-container__SUUkH {
    position: absolute;
    left: 52%;
    top: 5%;
    z-index: 9;
}

.custom-marker-mobile_point-name-container__SUUkH:hover > a {
    text-decoration: none;
}

.custom-marker-mobile_hovered-item__03p7A {
    position: absolute;
    z-index: 999;
    min-width: 20vw;
    max-width: 50vw;
}

.custom-marker-mobile_accordion-icon__xSJDT {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(149, 149, 149, 1);
    padding: 4px;
    border-radius: 400px;
}

.custom-marker-mobile_button-width__LJ_oU {
    width: 100%;
}

.all-address-popup_container-map__kbZDE {
    width: 100%;
    height: 100%;
    position: relative;
}

.all-address-popup_container-map__kbZDE > ymaps {
    overflow: hidden;
}

.all-address-popup_buttons-container__ZCYx8 {
    background-color: var(--color-background-light);
    padding: 2px;
    width: 92vw;
    margin-bottom: 13px;
    border-radius: 9px;
    margin-left: 16px;
    margin-right: 16px;
}

.all-address-popup_active-button__i03Jk {
    background-color: var(--color-background-primary);
    color: var(--color-text-primary);
    width: 50%;
    border-radius: 7px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 2%;
    transition: all 0.5s ease;
}

.all-address-popup_inactive-button__Qc7ak {
    color: var(--color-text-secondary);
    width: 50%;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 2%;
}

.all-address-popup_address-container__VkfFr {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom: 350px;
}

.all-address-popup_address-item-container__8gSo2 {
    padding-bottom: 8px;
    padding-top: 8px;
    &:not(:last-child) {
        border-bottom: 1px solid rgba(236, 236, 236, 1);
    }
}

.all-address-popup_list-container__OeZuD {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
}

.all-address-popup_button__3iKEt {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    bottom: 5%;
}

.all-address-popup_button-map__spkoS {
    position: -webkit-sticky;
    position: sticky;
    width: 92%;
    bottom: 5%;
    left: 4%;
}

.all-address-popup_input__oqE5F::placeholder {
    font-size: 14px;
}

.map-mobile-modal_container-map__dixpM {
    width: 100%;
    height: 100%;
}

.map-mobile-modal_container-map__dixpM > ymaps {
    overflow: hidden;
}

.map-mobile-modal_item__7WHrk:hover {
    background-color: rgba(235, 241, 237, 1);
    color: rgba(26, 26, 26, 1);
    border-radius: 8px;
}

.map-mobile-modal_icon-marker__cnz16 {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 50px;
    color: linear-gradient(0deg, #fd6b00 0%, #ea6300 37.53%, #da5c00 67.22%, #c95500 100%);
    z-index: 0;
    position: relative;
    transform: translate(-50%, -10%);
}

.map-mobile-modal_point-name-container__o0ieL {
    position: absolute;
    left: 52%;
    top: 5%;
    z-index: 9;
}

.map-mobile-modal_point-name-container__o0ieL:hover > a {
    text-decoration: none;
}

.map-mobile-modal_point-name__5DHNo {
    font-size: 13px;
    max-width: 5vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.map-mobile-modal_point-button__dDDGH {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.map-mobile-modal_point-button__dDDGH:hover {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.map-mobile-modal_cluster-name-container__vh6Ta:hover > a {
    text-decoration: none;
}

.map-mobile-modal_point-address__sIpxf {
    font-size: 10px;
    line-height: 10px;
    max-width: 8vw;
}

.map-mobile-modal_address-container__qZHfr {
    position: absolute;
    z-index: 999;
    top: 5%;
    left: 3%;
    max-width: 15vw;
}

.map-mobile-modal_address-list__mGd08 {
    max-height: 30vh;
    overflow-y: scroll;
}

.map-mobile-modal_bordered-text__w__2B {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border-primary);
}

.map-mobile-modal_button-width__sDUlP {
    width: 100%;
}

.map-mobile-modal_accordion-icon__qOZD9 {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(149, 149, 149, 1);
    padding: 4px;
    border-radius: 400px;
}

.map-mobile-modal_portal__LxepK > div {
    z-index: 100000;
    position: absolute;
    top: 0;
    right: 5%;
}

.map-mobile-modal_hovered-item__gmZTp {
    position: absolute;
    z-index: 999;
    min-width: 20vw;
    max-width: 50vw;
}

.mobile-view_mobile-header__IND1M {
    width: 90vw;
    display: flex;
    justify-content: space-between;
}

