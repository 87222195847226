.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:disabled {
    background-color: var(--color-background-light);
    color: var(--color-text-light);
    cursor: not-allowed;
}

.large {
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
}

.small {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    height: 40px;
}

.primary {
    background-color: var(--color-background-brand-green);
    color: var(--color-text-primary-inverse);
}

.primary:hover {
    background-color: var(--color-background-brand-green-hovered);
}

.primary:focus {
    background-color: var(--color-background-brand-green-hovered);
}

.primary:active {
    background-color: var(--color-background-brand-green-hovered);
}

.primary:disabled:hover {
    background-color: var(--color-background-light);
}

.accent {
    background-color: var(--color-background-brand-orange);
    color: var(--color-text-primary-inverse);
}

.accent:hover {
    background-color: var(--color-background-brand-orange-hovered);
}

.accent:focus {
    background-color: var(--color-background-brand-orange-hovered);
}

.accent:active {
    background-color: var(--color-background-brand-orange-hovered);
}

.secondary {
    background-color: var(--color-background-secondary);
    color: var(--color-text-brand-green);
}

.secondary:hover {
    background-color: var(--color-background-secondary-hovered);
}

.secondary:focus {
    background-color: var(--color-background-secondary-hovered);
}

.secondary:active {
    background-color: var(--color-background-secondary-hovered);
}

.outline {
    background-color: var(--color-background-primary);
    border: 1px solid;
    border-color: var(--color-border-primary-inverse);
    color: var(--color-text-primary);
}

.outline:hover {
    background-color: var(--color-background-secondary);
    border: 1px solid var(--color-border-brand-hovered);
    color: var(--color-text-primary);
}

.outline:focus {
    border: 1px solid var(--color-border-brand-hovered);
    color: var(--color-text-brand-green);
}

.outline:active {
    background-color: var(--color-background-brand-heavy);
    border: 1px solid var(--color-border-primary-inverse);
    color: var(--color-text-primary);
}

.ghost {
    background-color: var(--color-background-primary);
    border: none;
    color: var(--color-text-brand-green);
}

.ghost:hover {
    background-color: var(--color-background-secondary);
    border: none;
    color: var(--color-text-brand-green);
}

.ghost:focus {
    color: var(--color-text-brand-green);
}

.ghost:active {
    background-color: var(--color-background-primary);
    border: none;
    color: var(--color-text-brand-green);
}

.grey {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-secondary);
}

.grey:hover {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-brand-green-hovered);
}

.grey:focus {
    color: var(--color-text-brand-green-hovered);
}

.grey:active {
    background-color: var(--color-background-light);
    border: none;
    color: var(--color-text-brand-green-hovered);
}

.link {
    border: none;
    color: var(--color-text-brand-green);
    padding-left: 0;
}

.right-paddings {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
}

.left-paddings {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
}
