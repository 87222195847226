.h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 64px;
    color: var(--color-text-primary);
}

.h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: var(--color-text-primary);
}

.h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: var(--color-text-primary);
    letter-spacing: -0.5px;
}

.l {
    font-size: 20px;
    line-height: 24px;
}

.m {
    font-size: 16px;
    line-height: 20px;
}

.s {
    font-size: 14px;
    line-height: 20px;
}

.xs {
    font-size: 13px;
    line-height: 20px;
}

.xxs {
    font-size: 11px;
    line-height: 12px;
}

.bold {
    font-weight: 600;
    letter-spacing: -0.5px;
}

.medium {
    font-weight: 500;
    letter-spacing: -0.5px;
}

.regular {
    font-weight: 400;
    letter-spacing: -0.5px;
}

.primary {
    color: var(--color-text-primary);
}

.secondary {
    color: var(--color-text-secondary);
}

.light {
    color: var(--color-text-light);
}

.heavy {
    color: var(--color-text-heavy);
}

.inverse {
    color: var(--color-text-primary-inverse);
}

.green-brand {
    color: var(--color-text-brand-green);
}

.orange-brand {
    color: var(--color-text-brand-orange);
}

@media (min-width: 850px) {
    .h1 {
        letter-spacing: 2%;
    }

    .h2 {
        letter-spacing: 2%;
    }

    .h3 {
        letter-spacing: 0.02em;
    }

    .l {
        letter-spacing: 0.02em;
    }

    .m {
        letter-spacing: 0.02em;
    }

    .s {
        letter-spacing: 0.02em;
    }

    .xs {
        letter-spacing: 0.02em;
    }

    .bold {
        letter-spacing: 0.02em;
    }

    .medium {
        letter-spacing: 0.02em;
    }

    .regular {
        letter-spacing: 0.02em;
    }
}
